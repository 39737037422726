


import React, {useState, useEffect} from 'react';

import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import products from '../assets/data/products';
//courel
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from "../assets/images/Mosquito-20Away-20banner-02-desk_03_2024.jpg";
import ExampleCarouselImage2 from "../assets/images/LG_Tele_DD_03_2024.jpg";
import ExampleCarouselImage3 from "../assets/images/Cadeux pour l'amour_03_2024.jpg";

import Carousel1 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Product_Hs1 from '../components/Product-Slider/Product_Hs1';
import Product_Hs2 from '../components/Product-Slider/Product_Hs2';

import Product_Hs3 from '../components/Product-Slider/Product_Hs3';
import Product_Hs4 from '../components/Product-Slider/Product_Hs4';

import Product_Hs5 from '../components/Product-Slider/Product_Hs5';
import Product_Hs6 from '../components/Product-Slider/Product_Hs6';

import Product_Hs7 from '../components/Product-Slider/Product_Hs7';
import Product_Hs8 from '../components/Product-Slider/Product_Hs8';
// products LG
import Product_Lg1 from '../components/Product-Slider/Product_Lg1';
import Product_Lg2 from '../components/Product-Slider/Product_Lg2';
import Product_Lg3 from '../components/Product-Slider/Product_Lg3';
import Product_Lg4 from '../components/Product-Slider/Product_Lg4';
import Product_Lg5 from '../components/Product-Slider/Product_Lg5';
import Product_Lg6 from '../components/Product-Slider/Product_Lg6';
import Product_Lg7 from '../components/Product-Slider/Product_Lg7';
import Product_Lg8 from '../components/Product-Slider/Product_Lg8';

import Product_marqueEmtop from '../components/Product-Slider/Product_marqueEmtop';
import CorredoPaco from '../components/CorredoPaco/CorredoPaco';
import Product_Perkins from '../components/Product-Slider/Product_Perkins';

// products LG

import productImg10 from "../assets/images/xl7s.png";//audio
import productImg25 from "../assets/images/CG1.png";//congelateur
import productImg32 from "../assets/images/MH2535CIS.png";//micro-onde
import productImg28 from "../assets/images/lg_1.png";//cuisiniere
import productImg44 from "../assets/images/REFGLT682HQCL_2.png";//ref
import productImg62 from "../assets/images/split_5.png";//split
import productImg78 from "../assets/images/OLED55C1.jpg";//tv
import productImg104 from "../assets/images/Z09_Z9.png";//machine

//product Hisense
import productImg109 from "../assets/images/air_1.png";//air
import productImg113 from "../assets/images/SPEAKER_A.png";//audio
import productImg116 from "../assets/images/250FRUP2.jpeg";//congelateur
import productImg128 from "../assets/images/HBO6020203.jpeg";//cuisiniere
import productImg131 from "../assets/images/vaisselle_hisense.jpg";//lave vaissele
import productImg138 from "../assets/images/1243BT.jpeg";//machine
import productImg141 from "../assets/images/h30.png";//micro
import productImg155 from "../assets/images/66WR.jpg";//ref
import productImg170 from "../assets/images/hisense_1.png";//split
import productImg204 from "../assets/images/quantum_B.png";

//product Corredo & Paco

//product Perkins saco

//product Colors
import productImg01 from "../assets/images/double-sofa-01.png";
import productImg02 from "../assets/images/double-sofa-02.png";
import productImg03 from "../assets/images/double-sofa-03.png";
import productImg04 from "../assets/images/single-sofa-01.jpg";
import productImg05 from "../assets/images/single-sofa-02.jpg";
import productImg07 from "../assets/images/arm-chair-01.jpg";
import productImg08 from "../assets/images/arm-chair-02.jpg";
import productImg09 from "../assets/images/arm-chair-03.jpg";

//colors
import logo_colors from "../assets/images/logo_colors.png";

import emtop from '../assets/images/EMTOP_LOGO.jpg'

import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';

import { Container, Row, Col } from 'reactstrap';
import heroImg from '../assets/images/hero-img.png'

import Services from '../services/Services';
import ProductsList from '../components/Ul/ProductsList';
import Showroom from '../components/Showroom/Showroom';

import Emtop1 from '../components/Emtop/Emtop';

import Clock from '../components/Ul/Clock';

import counterImg from '../assets/images/counter-timer-img.png'
/*import Product_Slider_Colors from '../components/Product-Slider/Product_Slider_Colors';
import Product_Slider_Hisense from '../components/Product-Slider/Product_Slider_Hisense';

import Product_Slider_Corredo from '../components/Product-Slider/Product_Slider_Corredo';
import Product_Slider_Paco from '../components/Product-Slider/Product_Slider_Paco';
import Product_Slider_Perkins from '../components/Product-Slider/Product_Slider_Perkins';
*/

import Best from '../components/best/Best';
import Featured from '../components/featured/Featured';
import Best3 from '../components/Best3/Best3';

import Featured3 from '../components/Featured3/Featured3';

import Product_marqueCorredo1 from '../components/Product-Slider/Product_marqueCorredo';
import Product_marqueDorco1 from '../components/Product-Slider/Product_marqueDorco';
import Product_marqueHisense1 from '../components/Product-Slider/Product_marqueHisense';
import Product_marqueLg1 from '../components/Product-Slider/Product_marqueLg';
import Product_marqueNutribom1 from '../components/Product-Slider/Product_marqueNutribom';
import Product_marquePaco1 from '../components/Product-Slider/Product_marquePaco';
import Product_marqueEnzahome1 from '../components/Product-Slider/Product_marqueEnzahome';

import Product_Perk1 from '../components/Product-Slider/Product_Perk1';
import Product_Perk2 from '../components/Product-Slider/Product_Perk2';
import Product_Perk3 from '../components/Product-Slider/Product_Perk3';
import Product_Perk4 from '../components/Product-Slider/Product_Perk4';
import Product_Perk5 from '../components/Product-Slider/Product_Perk5';

//import 'bootstrap/dist/css/bootstrap.min.css';
import Aos from 'aos';
import 'aos/dist/aos.css';


import Product_Cor1 from '../components/Product-Slider/Product_Cor1';
import Product_Cor2 from '../components/Product-Slider/Product_Cor2';
import Product_Cor3 from '../components/Product-Slider/Product_Cor3';
import Product_Cor4 from '../components/Product-Slider/Product_Cor4';
import Product_Cor5 from '../components/Product-Slider/Product_Cor5';


import Product_Pac1 from '../components/Product-Slider/Product_Pac1';
import Product_Pac2 from '../components/Product-Slider/Product_Pac2';
import Product_Pac3 from '../components/Product-Slider/Product_Pac3';

import Product_Saco1 from '../components/Product-Slider/Product_Saco1';
import Product_Saco2 from '../components/Product-Slider/Product_Saco2';
import Product_Saco3 from '../components/Product-Slider/Product_Saco3';
import Product_Saco4 from '../components/Product-Slider/Product_Saco4';
import Product_Saco5 from '../components/Product-Slider/Product_Saco5';

//Carousel Hisense
import Image from 'react-bootstrap/Image';
import Image1 from '../assets/images/20240530-banner.jpg'; 
//import HisenseCarouselImage1 from '../components/CarouselMAGE/HisenseCarouselImage';

const Home = () => {

  const [trendingProducts, setTrendingProducts] = useState([]);
  const [bestSalesProducts, setBestSalesProducts] = useState([]);
  const [mobileProducts, setMobileProducts] = useState([]);
  const [wirelessProducts, setWirelessProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);


  const year = new Date().getFullYear();

  useEffect(()=>{
      const filteredTrendingProducts = products.filter(item=> item.category
        ==='chair'
        );
      const filteredBestSalesProducts = products.filter(item=> item.category
        ==='sofa'
        );

      const filteredMobileProducts = products.filter(item=> item.category
        ==='mobile'
        );

      const filteredWirelessProducts = products.filter(item=> item.category
        ==='wireless'
        );

      const filteredPopularProducts = products.filter(item=> item.category
        ==='watch'
        );

        setTrendingProducts(filteredTrendingProducts);
        setBestSalesProducts(filteredBestSalesProducts);
        setMobileProducts(filteredMobileProducts);
        setWirelessProducts(filteredWirelessProducts);
        setPopularProducts(filteredPopularProducts);
  }, []);


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide : 5  // facultatif, par défaut à 1. 
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
      slidesToSlide : 4  // facultatif, par défaut à 1.
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
      slidesToSlide : 2  // facultatif, par défaut à 1. 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide : 1  // facultatif, par défaut à 1. 
    },
  };
  useEffect(()=> {
    Aos.init({duration:2000});
  },[])

  

  return <Helmet title={"Home"}>
    <section className='pt-1'>
    <Carousel
      swipeable = { false } 
      draggable = { false } 
      showDots = { true } 
      responsive = { responsive } 
      ssr = { true }  // signifie afficher le carrousel côté serveur. 
      infinite = { true } 
      customTransition = "tous .5" 
      transitionDuration = { 500 } 
      containerClass = "carousel-container" 
      removeArrowOnDeviceType = { [ "tablette" ,  "mobile" ] } 
      
      dotListClass = "style-de-liste-de-points-personnalisé" 
      itemClass ="carousel-item-padding-40-px" 
      >
      <Carousel.Item>
        <img className='carousel' src={ExampleCarouselImage} text="" />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className='carousel' src={ExampleCarouselImage2} text=""  />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className='carousel' src={ExampleCarouselImage3} text="" />
        <Carousel.Caption>
         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>
    <section className="trending__products" data-aos="fade-up" >
      <Container>
        <Row>
          <Col lg='12' className='text-center lg mb-3'>
            <h6 className='section__title lg md-4 mb-3'></h6>
          </Col>
          <Best />
          <Carousel1 responsive={responsive} className='text-center'>
              <Product_Lg1 />
              <Product_Lg3/>
              <Product_Lg4/>
              <Product_Lg5/>
              <Product_Lg6/>
              <Product_Lg7/>
              <Product_Lg8/>
           </Carousel1>
           <Featured className='featured' />
        </Row>
      </Container>
    </section> 
    <section> <Image src={Image1} fluid className='imageHisense'/></section>  
   
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'></h2>
          </Col>
          <Best3 />
          <Carousel1 responsive={responsive} className='text-center'>
             <Product_Hs1 />
              <Product_Hs2 />
              <Product_Hs3 />
              <Product_Hs4 />
              <Product_Hs5 />
              <Product_Hs6 />
              <Product_Hs7 />
              <Product_Hs8 />          
           </Carousel1>
           <Featured3 />
        </Row>
      </Container>
    </section> 
    <section className="timer__count">
      <Container>
        <Row>
          <Col lg='6' md='12' className='count__down-col'>
            <div className="clock__top-content">
              <h4 className='text-white fs-6 mb-2'>Les offres Disponibles</h4>
              <h3 className='text-white fs-5 mb-3'>Fouani Congo</h3>
            </div>
            <Clock />
            <motion.button whileTap={{ scale: 1.2 }}
             className="buy__btn store__btn">
                <Link to="/shop">Visit Store</Link>
            </motion.button>
          </Col>
          <Col lg='6' md='12' className='text-end counter__img'>
            <img src={counterImg} alt="" />
          </Col>
        </Row>
      </Container>
    </section>

    <section className="trending__products"  data-aos="fade-up" >
      <Container>
        <Row>
          <Col lg='12' className='text-center mt-5'>
            <h2 className='section__title'></h2><img className='img-color' src={logo_colors} style={{width:'300px',height:'300px'}} alt="" data-aos="fade-up"/>
          </Col>
          <ProductsList data={bestSalesProducts} />          
        </Row>
      </Container>
    </section>
    
    <section className="best__sales" data-aos="fade-up">
      <Container>
      <Row>
          
          <Emtop1 />
        </Row>
      </Container>
    </section>
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <Link to="/catalogue_fouanicongo"><h2 className='section__title titre' data-aos="fade-up">Corredo & Paco</h2></Link>
          </Col>
          <Carousel1 responsive={responsive} className='text-center'>
             <Product_Cor1 />
              <Product_Pac1 />
              <Product_Cor2 />
              <Product_Pac2 />
              <Product_Cor3 />
              <Product_Pac3 />
              <Product_Cor4 />
              <Product_Cor5 />          
           </Carousel1>
        </Row>
      </Container>
    </section>
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <Link to="/catalogue_fouanicongo"><h2 className='section__title titre'>Perkins & Saco</h2></Link>
          </Col>

          <Carousel1 responsive={responsive} className='text-center'>
             <Product_Perk1 />
             <Product_Perk2 />
             <Product_Perk3 />
             <Product_Perk4 />
             <Product_Perk5 />
           </Carousel1>
        </Row>
      </Container>
    </section>
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'></h2>
          </Col>

          <Carousel1 responsive={responsive} className='text-center'>
             <Product_Saco1 />
             <Product_Saco2 />
             <Product_Saco3 />
             <Product_Saco4 />
             <Product_Saco5 />
            
           </Carousel1>
        </Row>
      </Container>
    </section> 
    <section className="trending__products hisense"  data-aos="fade-up">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'> Nos Marques</h2>
          </Col>

          <Carousel1 responsive={responsive} className='text-center'>
             <Product_marqueHisense1 />
             <Product_marqueCorredo1 />
              <Product_marqueEnzahome1 />
              <Product_marqueDorco1 />
              <Product_marqueLg1 />
              <Product_marqueNutribom1 />
              <Product_marquePaco1 />
              
              <Product_marqueEmtop />  
              <Product_Perkins />
           </Carousel1>
        </Row>
      </Container>
    </section> 
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
      <Col lg='12' className='text-center mb-5 md'>
      <h2 className='section__title titre'> Showroom</h2>
          </Col>
     
        <Showroom />
        
      </Container>
    </section>
    <Container>
    <Services/>
    </Container>
    
  </Helmet>;
};

export default Home;

