import React from 'react';
import './best3.css';
import img1 from '../../assets/images/csr.jpg';
import img2 from '../../assets/images/values.jpg';
import img3 from '../../assets/images/industrial.jpg';
import { Link } from 'react-router-dom';

import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';


function Best3() {

  useEffect(()=> {
    Aos.init({duration:2000});
  },[])
  return (
    <div className='best'>
        <Link to='/catalogue_fouanicongo'><h1 id='hisense-title'>Hisense Catalogue</h1></Link>
        <h2>Faites vos meilleurs choix</h2>
        {/**<div>
            <p><span className='bold'>All</span></p>
            <p>Commercial</p>
            <p>Commercial</p>
            <p>Commercial</p>
        </div> */}
        <div className='container' data-aos="fade-left">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
        </div>
        <Link to='/hisense'><button className='btn-best' data-aos="fade-left">Visitez Maintenant</button></Link>
    </div>
  )
}

export default Best3